import { VC } from "../models/vc";

// Fetch helper function
async function fetchData(input: string, init?: RequestInit) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}${input}`, {
        ...init,
        credentials: 'include',
    });

    if (response.ok) {
        return response;
    } else {
        const errorBody = await response.json();
        const errorMessage = errorBody.error || 'An error occurred';
        console.error(`Fetch error: ${errorMessage}`);
        throw new Error(errorMessage);
    }
}

// Fetch all VCs for a specific user
export async function fetchVCs(accessToken: string): Promise<VC[]> {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/vc`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Failed to fetch VCs');
    }
    return response.json();
}

// Fetch a specific VC
export async function fetchVC(vcId: string, accessToken: string): Promise<VC> {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/vc/${vcId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Failed to fetch VC');
    }
    return response.json();
}

// VC input interface
export interface VCInput {
    protocolsUrl?: string;
    exchangeUrl?: string;
    exchangeId: string;
    sequence?: string;
    ttl?: string;
    variables: [{
        var_start_date?: string;
        var_end_date: string;
        var_visitor_name?: string[];
        var_visitor_emails?: string[];
        var_location_name?: string;
        var_location_address?: string;
        var_rooms?: string[];
    }];
    state?: { 
        type?: string;
        enum?: ['pending', 'completed', 'failed'];
        default?: 'pending';
    };
    step?: string;
}

// Create a new VC
export async function createVC(vc: VCInput): Promise<VC> {    
    const response = await fetchData("/vc", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(vc),
    });
    return response.json();
}

// Delete a VC
export async function deleteVC(vcId: string) {
    await fetchData(`/vc/${vcId}`, { method: "DELETE" });
}