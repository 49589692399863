import React, { useState, useEffect } from 'react';
import { Button, Container, Dropdown, Spinner, Alert, Form, InputGroup } from 'react-bootstrap';
import { FaPlus, FaSearch } from "react-icons/fa";
import Location from '../Location';
import { Visitor } from "../../models/visitor";
import AddEditLocationDialog from '../AddEditLocationDialog';
import * as LocationsApi from "../../network/location_api";
import * as VisitorsApi from "../../network/visitor_api";
import { Location as LocationModel } from '../../models/location';
import styles from "../../styles/LocationsPage.module.css";

interface LocationsPageProps {
  accessToken: string;
}

const SORT_OPTIONS = {
  RECENT: "Most Recently Added",
  LEAST_RECENT: "Least Recently Added",
  ALPHABETICAL: "Alphabetical"
};

const LocationsPage = ({ accessToken }: LocationsPageProps) => {
  const [visitors, setVisitors] = useState<Visitor[]>([]);
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [showLocationsLoadingError, setShowLocationsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddLocationDialog, setShowAddLocationDialog] = useState(false);
  const [locationToEdit, setLocationToEdit] = useState<LocationModel | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState(SORT_OPTIONS.RECENT);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setShowLocationsLoadingError(false);

      try {
        const [fetchedLocations, fetchedVisitors] = await Promise.all([
          LocationsApi.fetchLocations(accessToken),
          VisitorsApi.fetchVisitors(accessToken)
        ]);

        setLocations(sortLocations(fetchedLocations, sortOrder));
        setVisitors(fetchedVisitors);
      } catch (error) {
        console.error("Failed to load data:", error);
        setShowLocationsLoadingError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [accessToken, sortOrder]);

  const sortLocations = (locations: LocationModel[], order: string) => {
    return [...locations].sort((a, b) => {
      switch (order) {
        case SORT_OPTIONS.ALPHABETICAL:
          return a.name.localeCompare(b.name);
        case SORT_OPTIONS.RECENT:
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        case SORT_OPTIONS.LEAST_RECENT:
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        default:
          return 0;
      }
    });
  };

  const filteredLocations = locations.filter(location =>
    location.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSortChange = (sortOption: string | null) => {
    if (sortOption) {
      setSortOrder(sortOption);
    }
  };

  const handleLocationClick = (location: LocationModel) => {
    setLocationToEdit(location);
    setShowAddLocationDialog(true);
  };

  const handleDeleteLocation = async (locationId: string) => {
    try {
      await LocationsApi.deleteLocation(locationId);
      setLocations(locations.filter(location => location._id !== locationId));
    } catch (error) {
      console.error("Failed to delete location:", error);
      setShowLocationsLoadingError(true);
    }
  };

  const handleLocationSaved = (updatedLocation: LocationModel) => {
    if (locationToEdit) {
      setLocations(locations.map(location => 
        location._id === updatedLocation._id ? updatedLocation : location
      ));
    } else {
      setLocations(prevLocations => sortLocations([updatedLocation, ...prevLocations], sortOrder));
    }
    setShowAddLocationDialog(false);
    setLocationToEdit(undefined);
  };

  return (
    <Container className={styles.locationsPage}>
      <h1>Locations</h1>

      {isLoading && <Spinner animation="border" role="status" className="my-3">
        <span className="visually-hidden">Loading...</span>
      </Spinner>}

      {showLocationsLoadingError && 
        <Alert variant="danger">
          Something went wrong while loading data. Please try again later.
        </Alert>
      }

      {!isLoading && locations.length > 0 ? (
        <>
          <div className={`${styles.headerControls} d-flex align-items-center gap-3 mb-3`}>
            <Dropdown onSelect={handleSortChange}>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Sort: {sortOrder}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.values(SORT_OPTIONS).map(option => (
                  <Dropdown.Item key={option} eventKey={option}>{option}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <div style={{width: '41rem'}}>
              <InputGroup className="flex-grow-1">
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search locations..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
            </div>
            <Button
              onClick={() => {
                setLocationToEdit(undefined);
                setShowAddLocationDialog(true);
              }}
            >
              <FaPlus /> Add New Location
            </Button>
          </div>

          <ul className={styles.locationList}>
            {filteredLocations.map((location) => (
              <li key={location._id}>
                <Location
                  location={location}
                  visitors={visitors}  
                  onLocationClicked={() => handleLocationClick(location)}
                  onDeleteLocationClicked={() => handleDeleteLocation(location._id)}
                />
              </li>
            ))}
          </ul>
        </>
      ) : (
        !isLoading && <p>No locations available.</p>
      )}

      {showAddLocationDialog && (
        <AddEditLocationDialog
          locationToEdit={locationToEdit}
          onDismiss={() => setShowAddLocationDialog(false)}
          onLocationSaved={handleLocationSaved}
        />
      )}
    </Container>
  );
};

export default LocationsPage;