import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Accordion } from "react-bootstrap";
import { Location as LocationModel } from "../models/location";
import { Visitor } from "../models/visitor";
import { formatDate } from "../utils/formatDate";
import { MdDelete, MdEdit, MdLocationOn, MdRoom, MdKey } from "react-icons/md";
import RoomVisitorAssignmentDialog from './VisitorAssignment';
import "../styles/location.css";
import { GoogleMap, Marker } from "@react-google-maps/api";

interface LocationProps {
  location: LocationModel;
  visitors: Visitor[];
  onLocationClicked: (location: LocationModel) => void;
  onDeleteLocationClicked: (location: LocationModel) => void;
  onRoomVisitorAssigned?: (visitorIds: string[], roomName: string, startDate: Date, endDate: Date) => void;
  className?: string;
}

const Location = ({
  location,
  visitors = [],
  onLocationClicked,
  onDeleteLocationClicked,
  onRoomVisitorAssigned,
  className,
}: LocationProps) => {
  const { name, address, room, description, createdAt, updatedAt, lat, lng } = location;
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<string | null>(null);
  const [hoveredRoomIndex, setHoveredRoomIndex] = useState<number | null>(null);
  const navigate = useNavigate();

  let createdUpdateText: string;
  if (updatedAt > createdAt) {
    createdUpdateText = "Updated: " + formatDate(updatedAt);
  } else {
    createdUpdateText = "Created: " + formatDate(createdAt);
  }

  const rooms = Array.isArray(room) ? room : room ? [room] : [];

  const handleRoomAssign = (visitorIds: string[], roomName: string, startDate: Date, endDate: Date) => {
    onRoomVisitorAssigned?.(visitorIds, roomName, startDate, endDate);
    setSelectedRoom(null);
  };

  const handleIssueVC = (roomName: string) => {
    navigate('/activeVC', {
      state: {
        location: {
          id: location._id,
          name: location.name,
          address: location.address,
          room: roomName
        }
      }
    });
  };

  return (
    <>
      <Accordion
        className={`location-accordion ${className}`}
        onSelect={(eventKey) => setIsAccordionOpen(eventKey === "0")}
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="d-flex justify-content-between align-items-center w-100 me-3">
              <div className="d-flex align-items-center">
                <MdLocationOn className="me-2" />
                <span className="fw-bold">{name}</span>
                <span className="mx-2">-</span>
                <span>{address}</span>
              </div>
              <span className="text-muted small">{createdUpdateText}</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md={12}>
                <h6 className="mb-3">Rooms:</h6>
                {rooms.length > 0 ? (
                  <ul className="list-unstyled">
                    {rooms.map((roomName, index) => (
                      <li key={index} className="mb-2 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <MdRoom className="me-2" />
                          {roomName}
                        </div>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          style={{
                            color: hoveredRoomIndex === index ? "white" : "#FFC107",
                            borderColor: "#FFC107",
                            backgroundColor: hoveredRoomIndex === index ? "#FFC107" : "transparent",
                            transition: "all 0.3s ease",
                          }}
                          onMouseEnter={() => setHoveredRoomIndex(index)}
                          onMouseLeave={() => setHoveredRoomIndex(null)}
                          onClick={(e) => {
                            e.preventDefault();
                            handleIssueVC(roomName);
                          }}
                        >
                          <MdKey className="me-1" /> Issue VC
                        </Button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-muted">No rooms added yet</p>
                )}

                {description && (
                  <>
                    <h6 className="mb-2 mt-4">Description:</h6>
                    <p>{description}</p>
                  </>
                )}

                {isAccordionOpen && lat !== 0 && lng !== 0 && (
                  <div className="mt-4">
                    <h6 className="mb-2">Location on Map:</h6>
                    <GoogleMap
                      mapContainerStyle={{
                        width: "100%",
                        height: "300px",
                      }}
                      center={{ lat, lng }}
                      zoom={15}
                    >
                      <Marker position={{ lat, lng }} />
                    </GoogleMap>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-end">
                <Button
                  variant="outline-primary"
                  className="me-2"
                  onClick={(e) => {
                    e.preventDefault();
                    onLocationClicked(location);
                  }}
                >
                  <MdEdit className="me-1" /> Edit
                </Button>
                <Button
                  variant="outline-danger"
                  className="me-2"
                  onClick={(e) => {
                    e.preventDefault();
                    onDeleteLocationClicked(location);
                  }}
                >
                  <MdDelete className="me-1" /> Delete
                </Button>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {selectedRoom && (
        <RoomVisitorAssignmentDialog
          roomName={selectedRoom}
          visitors={visitors}
          onDismiss={() => setSelectedRoom(null)}
          onAssign={handleRoomAssign}
        />
      )}
    </>
  );
};

export default Location;