import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../styles/LandingPage.module.css';  // Import your CSS module

function LandingPage() {

  const handleLoginRedirect = () => {
    const returnTo = '/visitors'; // Set the return path after login
    const encodedReturnTo = encodeURIComponent(`${process.env.REACT_APP_BASE_URL}${returnTo}`);
    const loginUrl = `${process.env.REACT_APP_BACKEND_API}/login?returnTo=${encodedReturnTo}`;
    // Redirect to the backend login page
    window.location.href = loginUrl;
  };

  return (
    <div className="landing-page">
      <Container fluid className="">
        <Row className={`text-center ${styles.centeredContent}`}>
          <Col>
            <h1 className="display-3 mb-4">Welcome to Visitor Credential Nexus</h1>
            <p className="lead text-muted mb-4">
              Manage visitors and locations effortlessly with our secure, user-friendly platform. 
              Login to get started.
            </p>
            <Button variant="primary" size="lg" onClick={handleLoginRedirect}>
              Login
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LandingPage;
